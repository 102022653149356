import React from "react"
import styles from "./whatWedo.module.scss"

function WhatWeDo({ items }) {
  return (
    <div className={styles.whatWeDoWrapper}>
      {items.map((item, index) => {
        const { title, subtitle, description, image } = item || {}

        return (
          <div className={styles.itemWrapper} key={index}>
            {image && <img src={image} alt={title} />}
            {title && <h5 className={styles.whatTitle}>{title}</h5>}
            {subtitle && <p className={styles.whatSubTitle}>{subtitle}</p>}
            {description && (
              <div
                className={styles.whatDescription}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
          </div>
        )
      })}
    </div>
  )
}

export default WhatWeDo
