import React from "react"
import styles from "./awards.module.scss"

const Awards = ({ items }) => {
  return (
    <div className={styles.awardsWrapper}>
      {items.map((item, index) => {
        const { title, image } = item || {}

        return (
          <div className={styles.itemWrapper} key={index}>
            <img src={image} alt={title} />
            <p>
              <strong>{title}</strong>
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default Awards
