export const header = {
  title: "About us",
  description:
    "Building great user experiences is what gets us out of bed. We’ve worked all over the world, from San Francisco to Beijing. Sao Paulo is also lovely in the spring.",
  flag: "Hi there",
  img: "avenue-hero-image-who",
  template: "background",
}
export const bodyCopy = {
  image: "avenue-about-intro-feature_q6pi35",
  variation: "bodyWrapper",
  html: `<h3>Transforming digital experiences with purpose-driven solutions</h3> <p><strong>At Avenue, we're driven by a passion for creating exceptional user experiences that drive business growth.</strong></p><p><strong>Our approach is rooted in a commitment to delivering value, not just to our clients but to their customers as well. We believe in the power of collaboration, transparency and innovation to make a meaningful impact.</strong></p>`,
}

export const htmlBlock = `<h3>Our Philosophy</h3><h5>Work hard & be nice to people</h5><p><strong>Our mantra encapsulates our approach to business and life. We strive to provide exceptional customer service through honesty, transparency, open communication and delivering products that exceed expectations.</strong></p><p>Our aim is to become an integral part of our clients' success, constantly delivering value and exceeding expectations.</p>`

export const whatWeDo = `<h3>What we do</h3><h5>Awesome digital solutions for ambitious businesses</h5><p>We specialise in creating people-first products that resonate with your audience. Our services include:</p>`

export const items = [
  {
    card: {
      heading: "Web Strategy",
      description:
        "Business-backed decision-making to build target customer focused digital experiences that position you for success.",
      image: {
        url: "https://res.cloudinary.com/avenue/image/upload/v1711587588/business-strategy_kgjmmh.png",
      },
    },
  },
  {
    card: {
      heading: "User Experience Design",
      description:
        "Designing intuitive online experiences based on empathy, research, and proven UX patterns. We ensure our solutions are a pleasure to use, with seamless navigation & clear communication.",
      image: {
        url: "https://res.cloudinary.com/avenue/image/upload/v1711587587/explore-user_api6yj.png",
      },
    },
  },
  {
    card: {
      heading: "User Interface Design",
      description:
        "Ensuring every pixel serves a purpose, guided by usability and branding principles. Our designs are beautiful, on-brand and resonate with your target audience.",
      image: {
        url: "https://res.cloudinary.com/avenue/image/upload/v1711587586/web-design_uzaqpi.png",
      },
    },
  },
  {
    card: {
      heading: "Web Development",
      description:
        "Cutting-edge headless websites and applications that combine immersive front-end design with leading, open-source driven back-end architecture.",
      image: {
        url: "https://res.cloudinary.com/avenue/image/upload/v1711587584/web-dev_fpbnjd.png",
      },
    },
  },
  {
    card: {
      heading: "Web Application Development",
      description:
        "We create business-driven web applications that deliver critical services, ensuring seamless operation and scalability to meet your evolving needs.",
      image: {
        url: "https://res.cloudinary.com/avenue/image/upload/v1711587584/research_ct1yp0.png",
      },
    },
  },
  {
    card: {
      heading: "Native App Development",
      description:
        "We develop engaging and functional native apps that provide essential services and foster deep interaction with your target audiences, enhancing user experience and engagement.",
      image: {
        url: "https://res.cloudinary.com/avenue/image/upload/v1711587583/phone-button_z2cjot.png",
      },
    },
  },
]

export const videoContent = {
  video: "https://vimeo.com/avenuedesign/chaos-to-clarity",
  light:
    "https://res.cloudinary.com/avenue/image/upload/v1711596478/video_dmq4nw.png",
}

export const ourContent = [
  {
    title: "Our Approach",
    subtitle: "Collaborative, transparent and direct",
    description:
      "<p>We believe in working closely with our clients, providing direct access to our directors, designers and developers for a smoother, smarter experience. Our working model challenges traditional agency practices, ensuring you won't have to worry about hidden fees or mixed messages.</p>",
  },
  {
    title: "Our Clients",
    subtitle: "From startups to enterprise businesses",
    description:
      "<p>We collaborate with a diverse range of clients, from startups and SMEs to product companies and enterprise businesses. We also partner with agencies and studios, putting great work above ego and competition. Our goal is to enhance and elevate your offering, no matter where you are in your business journey.</p>",
  },
  {
    title: "Our Success",
    subtitle: "Achieving  results for our clients",
    description:
      "<p>We take pride in the immense value we've provided to our clients through powerful web solutions focused on growth and stakeholder engagement.</p><p>Our dedication to excellence has been recognised with multiple global web awards, including:</p>",
  },
]

export const imagesTitles = [
  {
    image:
      "https://res.cloudinary.com/avenue/image/upload/v1711596860/ADA_zgjmbj.png",
    title: "Australian Design Awards",
  },
  {
    image:
      "https://res.cloudinary.com/avenue/image/upload/v1711596859/MDA_wmhgtu.png",
    title: "Melbourne Design Awards",
  },
  {
    image:
      "https://res.cloudinary.com/avenue/image/upload/v1711596856/IDA_cp65oq.png",
    title: "International Design Awards",
  },
  {
    image:
      "https://res.cloudinary.com/avenue/image/upload/v1711597115/ca_jge7hz.png",
    title: "Communicator Awards",
  },
  {
    image:
      "https://res.cloudinary.com/avenue/image/upload/v1711596854/w3_v4skcc.png",
    title: "W3 Awards",
  },
  {
    image:
      "https://res.cloudinary.com/avenue/image/upload/v1711596852/inda_zfpwny.png",
    title: "Indigo Design Award",
  },
  {
    image:
      "https://res.cloudinary.com/avenue/image/upload/v1711596850/da_w7jepr.png",
    title: "Davey Awards",
  },
  {
    image:
      "https://res.cloudinary.com/avenue/image/upload/v1711597153/vpda_lerxoq.png",
    title: "Victorian Premier’s Design Awards",
  },
]

export const avenueForGood = {
  image: "avenue-for-good-feature-wide.png",
  html: "<h3>Avenue for Good</h3><h4>Our commitment to social good</h4><p><strong>Our 'Avenue for Good' initiative supports small not-for-profit organisations, helping bring their vital missions to a wider audience in the digital space.</strong></p><p>It is driven by the idea of donating our time to help others, giving them that extra push needed to promote the amazing work they're already doing. Here’s what it’s all about:</p>",

  items: [
    {
      subtitle: "Supporting local communities",
      description:
        "<p>Whether your initiative is big or small, we want to support organisations that deliver purposeful impact for communities in need.</p>",
      image:
        "https://res.cloudinary.com/avenue/image/upload/v1713824221/supporting_ctbfrp.png",
    },
    {
      subtitle: "Promoting great causes",
      description:
        "<p>Our program takes a cause-first approach. If you’re doing something meaningful which could benefit from a stronger digital presence, we’d love to hear from you.</p>",
      image:
        "https://res.cloudinary.com/avenue/image/upload/v1713823915/promoting_wi6lh8.png",
    },
    {
      subtitle: "Sharing enriching experiences",
      description:
        "<p>We are true believers in the power of collaboration as a tool to help drive positive change and fulfilling experiences for everyone.</p>",
      image:
        "https://res.cloudinary.com/avenue/image/upload/v1713758205/sharing_z8v5e6.png",
    },
  ],
  middleText: [
    {
      html: "<h4>Who is it for?</h4><p>Large not-for-profit organisations usually have big budgets and a massive pool of resources at their disposal. We truly believe in ‘greatness from small beginnings’, so we wanted to support the little guys and help spread the word about the important work they’re doing.</p><h4>How it works</h5><p>Avenue’s team will donate their time and expertise to help bring your organisation’s vision to the digital space. If you’d like to participate, you’ll have to apply for the grant and tell us a little bit about your mission. We will then work with the selected applicants to develop their new digital solution.</p>",
    },
  ],
  endImage: "first_avenue_for_good",
  finalText: {
    html: "<h4>See one of our projects</h4><p>Goods 4 Good is a unique and inspiring gift shop that sources ethical products from Australian social enterprises dedicated to creating real-world impact. They champion a new approach to business by providing ethical gifts that contribute to various impactful causes, such as animal welfare and mental health.</p><p>Avenue worked closely with founder Nandeeta Maharaj to create <a href='https://www.goods4good.com.au/' target='blank' >Shopify website</a> at no cost, enabling Goods 4 Good to further their commitment to supporting ethical and sustainable ventures.</p>",
  },
}

export const getInTouch = {
  left: "Ready to transform your digital presence?",
  center:
    "If you're looking for a partner to help you navigate the digital landscape and drive business growth, Avenue is here to help. Contact us today to discover how we can create a website that truly speaks to your audience.",
  right: "Get in touch",
  action: "/contact",
}
