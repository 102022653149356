import React from 'react'
import { ToggleContactForm } from '../components/contactForm'

import styles from './getInTouch.module.scss'

export default ({ left, center, right, action, cols = [3, 6, 3] }) => {
  return (
    <section className={styles.block}>
      <div className={`row ${styles.row}`}>
        <div className={`${styles.columns} ${styles.first} `}>
          <span className={styles.leftCopy}>{left}</span>
        </div>

        {center && (
          <div
            className={`${styles.columns} ${styles.middle} columns large-${cols[1]}`}
          >
            <span className={styles.middleCopy}>{center}</span>
          </div>
        )}
        <div className={`${styles.columns} columns`}>
          <ToggleContactForm>{right}</ToggleContactForm>
        </div>
      </div>
    </section>
  )
}
