import React from "react"
import GetInTouch from "../components/getInTouch"
import { Link } from "gatsby"
import styled from "styled-components"
import servicesStyles from "../assets/styles/pages/services.module.scss"
import GlobalComponents from "../components/globalcomponents"
import InternalHeader from "../components/internalHeader"
import CSBodyCopy from "../components/caseStudies/CSBodyCopy"
import HtmlText from "../components/htmlText"
import ContentCards from "../components/ContentCards/ContentCards"
import HomeVideoModule from "../components/HomeVideoModule"
import {
  items,
  videoContent,
  imagesTitles,
  header,
  bodyCopy,
  htmlBlock,
  whatWeDo,
  getInTouch,
  ourContent,
  avenueForGood,
} from "../Content/about"
import Awards from "../components/Awards/awards"
import WhatWeDo from "../components/WhatWeDo/WhatWedo"
import FullWidthImage from "../components/fullWidthImage"

const ButtonLinkDefault = styled(Link)`
  color: #fff;
  font-size: 1.8rem;
  border-radius: 2px;
  border: 1px solid ${(props) => props.theme["neon-pink"]};
  padding: 22px 34px;
  display: inline-block;
  background: transparent;
  line-height: 2rem;
  font-family: ${(props) => props.theme.bodyFont};
  font-weight: 500;
  white-space: nowrap;
  transition: background 0.2s;

  @media (min-width: 1024px) {
    font-size: 2.4rem;
    padding: 24px 40px;
  }

  &:active,
  &:focus,
  &:hover {
    background: ${(props) => props.theme["purple-alt"]};
    text-decoration: none;
    outline: none;
  }
`

const ButtonLinkHollow = styled(ButtonLinkDefault)`
  border-color: ${(props) => props.theme.purple};
  color: ${(props) => props.theme.purple};
  width: 100%;
  text-align: center;
  margin-bottom: 50px;

  @media (min-width: 1024px) {
    width: initial;
    margin-bottom: 120px;
  }

  &:active,
  &:focus,
  &:hover {
    color: #fff;
  }
`
const AvenueTeamWrapper = styled.div`
  margin: 0 auto;
  max-width: 1190px;
  padding-right: 20px;
  padding-left: 20px;

  img {
    width: 100%;
  }
`

const AboutPage = () => (
  <GlobalComponents>
    <InternalHeader {...header} />
    <CSBodyCopy {...bodyCopy} />
    <HtmlText htmlBlock={htmlBlock} />
    <AvenueTeamWrapper>
      <img
        src="https://res.cloudinary.com/avenue/image/upload/v1711586494/team_n0xrr5.png"
        alt="Avenue Team photo"
      />
    </AvenueTeamWrapper>
    <HtmlText htmlBlock={whatWeDo} />
    <ContentCards items={items} />
    <HomeVideoModule video={videoContent.video} light={videoContent.light} />
    <div className="row">
      <WhatWeDo items={ourContent} />
    </div>
    {imagesTitles && (
      <div className="row">
        <Awards items={imagesTitles} />
      </div>
    )}
    <div className={servicesStyles.centerButtons}>
      <ButtonLinkHollow to="/work" type="hollow">
        Check out our work
      </ButtonLinkHollow>
    </div>
    <FullWidthImage
      img={avenueForGood.image}
      margin="none"
      newmaxheight="newmaxheight"
    />
    <HtmlText htmlBlock={avenueForGood.html} />
    <WhatWeDo items={avenueForGood.items} />
    {avenueForGood?.middleText.map((item, index) => {
      return <HtmlText key={index} htmlBlock={item.html} />
    })}
    <FullWidthImage img={avenueForGood.endImage} maxWidth="standard" />
    <HtmlText htmlBlock={avenueForGood.finalText.html} />
    <div className={servicesStyles.centerButtons}>
      <ButtonLinkHollow
        to="https://www.goods4good.com.au/"
        type="hollow"
        target="blank"
      >
        View the website
      </ButtonLinkHollow>
    </div>
    <GetInTouch {...getInTouch} />
  </GlobalComponents>
)

export default AboutPage
