import React from 'react'
import styles from '../../assets/styles/pages/services.module.scss'
import styled from 'styled-components'
import { Row, media } from '../../utils/style-utils'

const Col = styled.div`
  flex: 0 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  flex-basis: 100%;
  max-width: 100%;

  ${media.large`
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  `};
`
function ContentCards({ heading, description, link, items }) {
  return (
    <Services styleType="services" className="content__cards">
      {(description || heading) && (
        <div className="columns large-12">
          {heading && <h2 className={styles.constentCards}>{heading}</h2>}
          {description && (
            <p className={styles.constentCardsSubheading}>{description}</p>
          )}
        </div>
      )}

      <Row>
        {items.map((item, index) => {
          const { heading, image, description, link } = item.card || {}
          const { type, custom_link, internal_link, text } = link || {}
          const linkUrl = type === 'custom' ? custom_link : internal_link
          return (
            <ServiceCard
              key={index}
              title={heading}
              image={image}
              link={linkUrl}
              text={text}
            >
              {description}
            </ServiceCard>
          )
        })}
      </Row>

      {/* <div className="columns large-12">
        <div className={styles.centerButtons}>
          <ButtonLinkHollow to="/work">View our work</ButtonLinkHollow>
          <ButtonLinkHollow to="/about">Get to know us</ButtonLinkHollow>
        </div>
      </div> */}
    </Services>
  )
}

export default ContentCards

export const Services = ({ children, styleType, className }) => (
  <section className={(styles[styleType], styles[className])}>
    <div className="row">{children}</div>
  </section>
)

export const ServiceCard = ({ title, image, children, link, text }) => (
  <Col className={styles.newCardCol}>
    <div className={styles.service}>
      <img src={image.url} alt={title} />
      <div className={styles.cardDetails}>
        <h3>{title}</h3>
        <p>{children}</p>
        {link && (
          <a href={link} className={styles.cardButtons}>
            {text}
          </a>
        )}
      </div>
    </div>
  </Col>
)
