import React from "react"
import styles from "./fullWidthImage.module.scss"
import Img from "./responsiveCloudinary"

export default ({
  noLine,
  img,
  label,
  name,
  margin,
  nomargintop,
  newmaxheight,
  variation,
  maxWidth,
}) => {
  return (
    <div
      className={`
        ${styles.container}
        ${styles[nomargintop]}
        ${styles[newmaxheight]}
        ${styles[maxWidth]}
        ${styles[margin]}

      `}
    >
      <div className={styles.img__container}>
        <Img
          className={`${styles.image} ${styles[variation]}`}
          user="avenue"
          publicId={img}
          alt=""
        />
      </div>

      {label && (
        <div className={styles.label}>
          <div className={`${styles.line} ${styles[noLine]}`} />
          <p className={styles.text}>
            {" "}
            {label} <span>{name}</span>
          </p>
        </div>
      )}
    </div>
  )
}
