import React from "react"
import styles from "./htmlText.module.scss"
import styled from "styled-components"

export default ({ htmlBlock, margin, background }) => {
  function createMarkup() {
    return { __html: htmlBlock }
  }

  let inlineStyle = {
    backgroundColor: background || null,
  }

  return (
    <div style={inlineStyle} className={`${styles.row} ${styles[margin]}`}>
      <Content dangerouslySetInnerHTML={createMarkup()} />
    </div>
  )
}

const Content = styled.div`
  padding: 0 20px;
  text-align: left;

  @media (min-width: 480px) {
    padding: 0 40px;
  }

  *:first-child {
    margin-top: 0;
  }

  > p:first-child {
    font-weight: 400;
    color: #4a5051;

    @media (min-width: 768px) {
      font-size: 2.6rem;
    }
  }

  ul {
    list-style-type: none;
    margin-bottom: 3rem;
    li::before {
      content: "";
      height: 6px;
      width: 6px;
      background: $purple;
      display: block;
      border-radius: 50%;
      position: absolute;
      top: 10px;
      left: -20px;

      @media (min-width: 768px) {
        top: 14px;
      }
    }
  }

  .wp-block-quote {
    background: #bc237d;
    width: calc(100% + 80px);
    padding: 40px;
    margin: 40px 0 40px -40px;
    text-align: center;

    p {
      color: #fff;
      line-height: 1.125;
      font-size: 24px;
      max-width: 900px;
      font-weight: 400;
      margin: 0 auto !important;

      &:before {
        content: "“";
      }
      &:after {
        content: "”";
      }

      @media (min-width: 768px) {
        font-size: 32px;
      }
      @media (min-width: 1280px) {
        font-size: 36px;
      }
    }

    cite {
      max-width: 900px;
      font-weight: 300;
      margin: 0 auto;
      display: block;
      font-style: initial;
      color: #fff;
      font-family: Avenir;
      line-height: 1.4;
      font-size: 16px;
      text-align: center;
      border-top: 2px solid #dd449b;
      padding-top: 30px;
      margin-top: 30px;

      strong {
        color: #fff;
      }
    }

    @media (min-width: 768px) {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      margin: 80px auto;
      width: 100vw;
    }

    @media (min-width: 1280px) {
      padding: 100px 0;
    }
  }

  p.has-footnote-font-size {
    padding-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 1.6rem !important;
    color: #66777a !important;

    @media (min-width: 768px) {
      padding-top: 30px;
      font-size: 1.8rem !important;
    }
    a {
      font-size: inherit;
    }
  }

  ol {
    list-style: none;
    counter-reset: item;
    margin-bottom: 3rem;

    li {
      counter-increment: item;
      margin-bottom: 5px;
      position: relative;
    }
    li:before {
      content: counter(item);
      color: #bc237d;
      width: 1.2em;
      text-align: center;
      display: inline-block;
      position: absolute;
      top: 0px;
      font-weight: 500;
      left: -26px;

      @media (min-width: 768px) {
        left: -31px;
      }
    }
  }

  .wp-block-embed.is-type-video {
    width: 100%;
    position: relative;
    margin: 40px auto;
    padding-bottom: 56.25%;

    @media (min-width: 1024px) {
      width: calc(100% + 320px);
      padding-bottom: 83.25%;
      margin: 80px 0 80px -160px;
    }
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .wp-block-buttons {
    text-align: center;
  }

  .wp-block-button__link {
    color: ${(props) => props.theme.purple};
    font-size: 1.8rem;
    border-radius: 2px;
    border: 1px solid ${(props) => props.theme["purple"]};
    padding: 22px 34px;
    display: inline-block;
    background: transparent;
    line-height: 2rem;
    font-family: ${(props) => props.theme.bodyFont};
    font-weight: 500;
    white-space: nowrap;
    transition: background 0.2s;

    @media (min-width: 1024px) {
      font-size: 2.4rem;
      padding: 24px 40px;
    }

    &:active,
    &:focus,
    &:hover {
      background: ${(props) => props.theme["purple-alt"]};
      text-decoration: none;
      outline: none;
      color: #fff;
    }
  }

  .wp-block-image {
    margin: 40px auto;
    width: 100%;
    text-align: center;

    @media (min-width: 1024px) {
      margin: 80px 0;
    }
    &.size-large img,
    &.size-full img {
      width: 100%;

      @media (min-width: 768px) {
        width: 100vw;
        transform: translateX(-50%);
        position: relative;
        left: 50%;
        max-width: 1320px;
      }
    }

    &.alignfull img {
      max-width: 100vw;
    }

    .size-medium img {
      width: auto;
    }
    .aligncenter {
      text-align: center;
    }

    figcaption {
      font-family: "Avenir", sans-serif;
      font-size: 12px;
      color: #869599;
      letter-spacing: -0.11px;
      display: inline-block;
      padding-top: 10px;

      @media (min-width: 768px) {
        font-size: 14px;
        padding-top: 16px;
      }
    }
  }
  h3 {
    margin-bottom: 3rem;
  }

  h4 {
    padding-right: 40px;
    margin-bottom: 3rem;
  }

  p {
    margin-bottom: 3rem;

    strong {
      color: #66777a;
      font-weight: 500;
    }
    &:last-child {
      margin: 0;
    }
  }

  strong {
    color: #202020;
    font-weight: 500;
  }

  @media (min-width: 768px) {
    flex-basis: 80%;
    max-width: 80%;
    flex: 0 0 auto;
    padding: 0 20px;
  }

  @media (min-width: 1024px) {
    max-width: 60%;
    flex-basis: 60%;
  }

  @media (min-width: 1280px) {
    max-width: 60%;
    flex-basis: 60%;
  }

  .acf-block--custom__quote.testi__container {
    background: #bc237d;
    display: flex;
    flex-direction: column;
    width: calc(100% + 40px);
    margin-bottom: 50px;
    padding: 40px 40px;
    margin-left: -20px;

    @media (min-width: 480px) {
      width: calc(100% + 80px);
      margin-left: 50%;
      transform: translateX(-50%);
    }
    @media (min-width: 768px) {
      width: 100vw;
      padding: auto 100px;
    }
    @media (min-width: 1024px) {
      padding: 60px 120px;
    }

    @media (min-width: 1280px) {
      display: flex;
      flex-direction: row;
      margin-bottom: 120px;
      padding: 0 0 110px 0;
      transform: translateX(0);
      margin-left: -50.43%;
      padding-right: 10%;

      .column__left {
        width: 75%;
      }

      .column__right {
        width: 25%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }

    @media (min-width: 1800px) {
      padding-right: 54%;
      margin-left: -65%;
    }
    @media (min-width: 2000px) {
      padding-right: 64%;
      margin-left: -65%;
    }
  }

  .testi__text::before {
    content: "“";
  }
  .testi__text::after {
    content: "”";
  }

  .testi__text {
    font-family: FoundersGrotesk;
    font-weight: 400;
    font-size: 24px;
    color: #ffffff;
    letter-spacing: -0.25px;
    line-height: 32px;
    border-bottom: 1px solid #dd449b;
    quotes: '"' '"' "'" "'";
    padding-bottom: 30px;

    @media (min-width: 768px) {
      font-size: 32px;
      letter-spacing: -0.26px;
      line-height: 36px;
    }
    @media (min-width: 1280px) {
      font-size: 36px;
      letter-spacing: -0.38px;
      line-height: 48px;
      border-bottom: none;
      border-right: 2px solid #dd449b;
      padding: 0 90px 0 0;
      margin: 110px 0 0 120px;
    }
  }

  .testi__client {
    font-family: Avenir;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: -0.12px;
    line-height: 24px;
    padding-top: 25px;

    @media (min-width: 1280px) {
      padding: 0;
      margin-left: 40px;
    }
  }

  .testi__position {
    font-family: Avenir;
    font-weight: 300;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: -0.12px;
    line-height: 24px;

    @media (min-width: 1280px) {
      padding: 0;
      margin-left: 40px;
    }
  }
  .testi__business {
    font-family: Avenir;
    font-weight: 300;
    font-size: 16px;
    color: #ffffff;
    letter-spacing: -0.12px;
    line-height: 24px;
    padding-bottom: 40px;

    @media (min-width: 1280px) {
      padding: 0;
      margin-left: 40px;
    }
  }
`
