import React from "react"

import Img from "../responsiveCloudinary"
import styles from "./CSBodyCopy.module.scss"
import styled from "styled-components"

const Row = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  flex-wrap: wrap;

  @media (min-width: 1600px) {
    max-width: 1480px;
  }
`

const Col = styled.div`
  flex: 0 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  flex-basis: 100%;
  max-width: 100%;
  position: relative;

  @media (min-width: 1024px) {
    flex-basis: 50%;
    max-width: 50%;
    display: ${(props) => (props.contentVCenter ? "flex" : "block")};
  }

  img {
    align-self: center;
  }
`

export default ({ children, image, html, variation, quality, css }) => {
  return (
    <section className={`${styles.bodyWrapper} ${styles[variation]}`}>
      <Row>
        <Col>
          <div
            className={styles.markDownCopy}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </Col>
        <Col contentVCenter>
          <Img
            user="avenue"
            publicId={image}
            quality={quality}
            sourceSizes={[500, 700, 1400, 1900]}
            small="100vw"
            large="950px"
            alt=""
          />
        </Col>
      </Row>
    </section>
  )
}
