import React from "react"
import Helmet from "react-helmet"
import Img from "../components/responsiveCloudinary"

import styles from "./internalHeader.module.scss"

export default ({
  title,
  flag,
  img,
  template,
  description,
  margin,
  size,
  headerTitle,
}) => {
  return (
    <div className={`${styles[margin]}`}>
      <section
        className={`${styles.block} ${styles[template]} ${styles[size]}`}
      >
        <Helmet>
          <title>{title}</title>
          <meta name="Description" content={description} />
        </Helmet>
        <div className={`row ${styles.row}`}>
          <div className={`columns large-12 ${styles.column}`}>
            <span className={styles.flag}>{flag}</span>
            <h1 className={styles.title}>{headerTitle || title}</h1>
            {template !== "background" && (
              <Img user="avenue" publicId={img} scaleInOnLoad />
            )}
          </div>
        </div>
        {template === "background" && (
          <Img user="avenue" publicId={img} scaleInOnLoad />
        )}
      </section>
    </div>
  )
}
