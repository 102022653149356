import React from 'react'
import styled from 'styled-components'
import Video from './video'

const Wrapper = styled.div`
  text-align: center;
`
const HomeVideoModule = ({ title, subtitle, video, light }) => {
  return (
    <Wrapper>
      {title && <h3>{title}</h3>}
      {subtitle && <p>{subtitle}</p>}

      <Video video={video} light={light} playing />
    </Wrapper>
  )
}

export default HomeVideoModule
